


































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
const columns = [
    {title: '名称', key: 'name'},
    {title: '经度', key: 'lon'},
    {title: '纬度', key: 'lat'},
    // // width: 400
    // {title: '操作', slot: 'action'},
]
export default Vue.extend({
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            columns,
            data: []as any,
        }
    },
    mounted() {
        this.data = [
            {
                id: '1',
                // name: '站号20024',
                name: '西安输油末站',
                // name: '陕西延长石油第五分公司',
                imgIcon: '/static/image/monitor/legend_1.png',
                lon: '109.13478330776976',
                lat: '34.3400605877569',
            },
        ]
        // this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            request.get('/api/respond/respond_role/list', null)
            .then((data) => {
                this.data = data
                this.loading = false
            })
        },
        deleteItem(id: string) {
            // request.del(`/api/respond/respond_role/${id}`)
            // .then((res) => {
            //     this.$Message.success('删除成功!')
            //     this.getData()
            // }).catch((errorMsg) => {
            //     this.$Message.error(errorMsg)
            // })
        },
        /* 改变pageNum */
        changPageNum(num: number) {
            this.pageNum = num
            this.getData()
        },
    },
})
